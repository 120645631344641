import {useState} from 'react'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import {useAccessToken} from '@salesforce/commerce-sdk-react'
import {ApiClient} from '../services/api'

export class OrdergrooveApi {
    base(method, siteId, tokenId, url) {
        const queryParams = {
            siteId: siteId
        }
        const apiUrl = `${url}?${new URLSearchParams(queryParams)}`
        return fetch(apiUrl, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${tokenId}`
            }
        })
    }

    get(siteId, tokenId, url) {
        return this.base('get', siteId, tokenId, url)
    }
}

const useOrdergroove = () => {
    const {getTokenWhenReady} = useAccessToken()
    const {site} = useMultiSite()
    const ordergroove = new OrdergrooveApi()
    const [subscribedProducts, setSubscribedProducts] = useState('')

    const getOGCustomerCookie = async () => {
        const token = await getTokenWhenReady()
        const url = '/api/ordergroove/getOGCustomerCookie'
        const response = await ordergroove.get(site?.id, token, url)
        return await response.json()
    }

    const getOGCustomerSubscriptions = async () => {
        const token = await getTokenWhenReady()
        const url = '/api/ordergroove/getActiveSubscriptionProductIds'
        const response = await ordergroove.get(site?.id, token, url)
        const subscriptionProducts = await response.json()
        setSubscribedProducts(subscriptionProducts.subscriptions)
    }

    const setForAllSubscriptions = async (request, token, site) => {
        const baseUrl = '/api/ordergroove/useForAllSubscriptions'
        const apiClient = new ApiClient(baseUrl, token, site)
        
        const res = await apiClient.post({
            body: JSON.stringify({
                data: request
            })
        })
        if (res.status >= 300) {
            throw new Error(res)
        } else {
            return await res.json()
        }
    }

    return {
        getOGCustomerCookie,
        getOGCustomerSubscriptions,
        setForAllSubscriptions,
        subscribedProducts
    }
}

export default useOrdergroove

import React, {useEffect} from 'react';
import {useShopperBasketsMutation} from '@salesforce/commerce-sdk-react'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'

const OrdergrooveScript = ({ basket, src, isOGOptinRefreshed, setIsOGOptinRefreshed, children }) => {
    const updateBasket = useShopperBasketsMutation('updateBasket')
    const {
        app: {nonce}
    } = getConfig()
    useEffect(() => {
        if (basket?.id && window?.basketId && basket.id !== window.basketId) {
            window.basketId = basket.id
        }
        if (!isOGOptinRefreshed) {
            const script = document.createElement('script')
            script.src = src
            script.async = true
            script.onload = () => {
                if (basket && window?.OG) {
                    updateBasket.mutate({
                        parameters: { basketId: basket.basketId },
                        body: { c_subscriptionOptins: JSON.stringify(window?.OG?.getOptins(), null, 1) }
                    })
                    window.basketId = basket.basketId
                    window.OG.addOptinChangedCallback(() => {
                        updateBasket.mutate({
                            parameters: { basketId: window.basketId },
                            body: { c_subscriptionOptins: JSON.stringify(window?.OG?.getOptins(), null, 1) }
                        })
                    })
                    setIsOGOptinRefreshed(true)
                }
            }
            if (nonce) {
                script.nonce = `${nonce}`
            }
            document.body.appendChild(script)
            return () => {
                document.body.removeChild(script)
            };
        }
    }, [basket]);

    return <>{children}</>;
};

export default OrdergrooveScript;
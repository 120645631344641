import {useState} from 'react'
import {helpers} from 'commerce-sdk-isomorphic'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import {useAccessToken, useCommerceApi} from '@salesforce/commerce-sdk-react'
const BASE_URI = 'https://{shortCode}.alternativeBaseUri.com/custom/{apiName}/{apiVersion}'

export class CustomApi {
    // Calling API
    base(method, token, apiName, endpointPath, parameters, proxy, body) {
        let options = {
            method: method,
            parameters: parameters,
            headers: this.getHeader(token),
            customApiPathParameters: this.getCustomApiPathParameters(apiName, endpointPath)
        }
        if (body) {
            options.body = JSON.stringify(body)
        }
        return helpers.callCustomEndpoint({
            options: options,
            clientConfig: this.getClientConfig(apiName, proxy),
            rawResponse: false,
        })
    }

    // Get Request
    get(token, apiName, endpointPath, parameters, proxy) {
        return this.base('get', token, apiName, endpointPath, parameters, proxy)
    }

    // POST Request
    post(token, apiName, endpointPath, parameters, proxy, body) {
        return this.base('post', token, apiName, endpointPath, parameters, proxy, body)
    }

    // Get Header
    getHeader(token) {
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    // Get Custom API Path Parameters
    getCustomApiPathParameters(apiName, endpointPath) {
        return {
            apiName: apiName,
            endpointPath: endpointPath,
            apiVersion: 'v2'
        }
    }

    // Get Client Config
    getClientConfig(apiName, proxy){
        const {
            app: {commerceAPI}
        } = getConfig()
        return {
            parameters: {
                ...commerceAPI.parameters,
                apiName: apiName
            },
            proxy: proxy,
            baseUri: BASE_URI
        }
    }
}

const useCustomApi = () => {
    const {getTokenWhenReady} = useAccessToken()
    const customapi = new CustomApi()
    const api = useCommerceApi()
    const {proxy} = api.shopperExperience.clientConfig

    // Save content asset body as state in hook
    const [contentAssetBody, setContentAssetBody] = useState('')

    // Fetch content asset by id via custom API
    const fetchContentAssetWithId = async (contentAssetId) => {
        const token = await getTokenWhenReady()
        const apiName = 'content-asset'
        const endpointPath = '/'
        const parameters = {
            c_contentAssetId: contentAssetId
        }
        const response = await customapi.get(token, apiName, endpointPath, parameters, proxy)
        setContentAssetBody(response?.contentAssetHTML)
    }

    // Sync customer via custom API
    const syncProfile = async (idpAccessToken, selectedIDP) => {
        const token = await getTokenWhenReady()
        const apiName = 'sync-profile'
        const endpointPath = '/'
        const parameters = {
            c_idpAccessToken: idpAccessToken,
            c_idpName: selectedIDP
        }
        await customapi.post(token, apiName, endpointPath, parameters, proxy)
    }

    return {
        fetchContentAssetWithId,
        syncProfile,
        contentAssetBody
    }
}

export default useCustomApi
import React, { useEffect } from 'react';

const GtmScript = ({ gtagContainerID, nonce }) => {
    useEffect(() => {
        if (gtagContainerID) {
           
            const inlineScript = document.createElement('script');
            inlineScript.type = 'text/javascript';
            inlineScript.setAttribute('nonce', nonce);
            inlineScript.innerHTML = `
                (function(w,d,s,l,i){
                    w[l]=w[l]||[];
                    w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
                    j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                    var n=d.querySelector('[nonce]');
                    n&&j.setAttribute('nonce', n.nonce || n.getAttribute('nonce'));
                    f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${gtagContainerID}');
            `;

            document.head.appendChild(inlineScript);

            return () => {
                document.head.removeChild(inlineScript);
            };
        }
    }, [gtagContainerID, nonce]);

    return null;
};

export default GtmScript;

export const formatName = (value) => {
    if (!value) return value
    const name = value.replace(/[^a-zA-Z\-'., ]/g, "")
    return name.length < 50 ? name : name.substring(0, 50)
}

export const formatOrderNo = (value) => {
    if (!value) return value
    return value.length < 50 ? value : value.substring(0, 50)
}

export const formatAddress = (value) => {
    if (!value) return value
    const address = value.replace(/[^A-Za-z0-9“”‘’'\.\-\s\,\!?:;\[\](){}"]/g, "");
    return address.length < 50 ? address : address.substring(0, 50)
}

export const formatPostalCode = (value) => {
    if (!value) return value
    const postalCode = value.replace(/[^\d]/, "")
    return postalCode.length < 5 ? postalCode : postalCode.substring(0, 5)
}

export const validateEmail = (value) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
}

export const formatPhoneNumber = (value) => {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

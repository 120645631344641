import {getDisplayPrice} from '@salesforce/retail-react-app/app/utils/product-utils'

export default function useTagManager() {
    
    return {
       
        push(...args) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(...args)
        },

        productData(product, options = null) {
            let result = {
                item_id: product.id || product.productId,
                item_name: product.name || product.productName,
                quantity: typeof product.quantity !== 'undefined' ? product.quantity : 1,
                price: product.price
            }
            if (product.brand) {
                result.item_brand = product.brand
            }

            if (options?.applyOrderPromotions && product.priceAfterOrderDiscount) {
                result.price = product.priceAfterOrderDiscount
                result.discount = product.price - product.priceAfterOrderDiscount
            } else if (
                product.priceAfterItemDiscount &&
                product.priceAfterItemDiscount < product.price
            ) {
                result.price = product.priceAfterItemDiscount
                result.discount = Math.abs(
                    product?.priceAdjustments?.reduce(
                        (acc, adjustment) => acc + adjustment.price,
                        0
                    )
                )
            } else {
                const displayPrice = getDisplayPrice(product)
                if (displayPrice && displayPrice.discountPrice > 0) {
                    result.price = displayPrice.discountPrice
                    result.discount = displayPrice.basePrice - displayPrice.discountPrice
                }
            }

            result.price = parseFloat(Number(result.price / result.quantity).toFixed(2))
            if (result.discount) {
                result.discount = parseFloat(Number(result.discount / result.quantity).toFixed(2))
            }

            let itemVariant = []
            if (product?.type?.bundle || product?.productType?.bundle) {
                itemVariant.push('bundle')
            }
            if (product?.type?.master || product?.productType?.master) {
                itemVariant.push('master')
            }
            if (product?.type?.variant || product?.productType?.variant) {
                itemVariant.push('variant')
            }
            if (itemVariant.length > 0) {
                result.item_variant = itemVariant.join(' ')
            }

            if (product?.primaryCategoryId) {
                result.item_category = product.primaryCategoryId
            }
            result.is_subscribed = product?.priceAdjustments?.some(adjustment => adjustment?.promotionId === "OrdergrooveDPI")
            if (product?.priceAdjustments?.length > 0) {
                const appliedUniqueProductCoupons = [
                    ...new Set(product?.priceAdjustments?.map((c) => c?.couponCode))
                ]
                result.coupon = appliedUniqueProductCoupons.join(' ')
            }

            return result
        },

        productListData(listID, listName, productArray, offset, options = null) {
            let result = []
            productArray.forEach((product, index) => {
                let productData = this.productData(product, options)

                if (listID) {
                    productData.item_list_id = listID
                }
                if (listName) {
                    productData.item_list_name = listName
                }

                productData.index = index + 1 + (offset || 0)

                result.push(productData)
            })
            return result
        },

        async sendViewPage(pageLocation, customer) {
            this.push({
                event: 'page_view',
                page_location: pageLocation,
                customer: {
                    id: customer?.customerId,
                    type: customer?.customerType,
                    is_registered: customer?.isRegistered
                }
            })
        },

        async sendViewProduct(product) {
            this.push({
                event: 'view_item',
                ecommerce: {
                    items: [this.productData(product)]
                }
            })
        },

        async sendViewCart(basket) {
            this.push({
                event: 'view_cart',
                ecommerce: {
                    currency: basket.currency,
                    value: basket.productSubTotal, // sum of price * quantity, after applying product level promos
                    items: this.productListData(null, null, basket.productItems, 0)
                }
            })
        },

        /**
         *
         * @param {string} listID
         * @param {string} listName
         * @param {array} result productSearchResult | recommendations from einstein
         */
        async sendViewList(listID, listName, result) {
            let productArray = []
            let offset = 0
            if (typeof result.hits !== 'undefined') {
                // productSearchResult
                productArray = result.hits
                offset = result.offset
            } else if (typeof result.recs !== 'undefined') {
                // recommendations from einstein
                productArray = result.recs
            }
            let data = {
                event: 'view_item_list',
                ecommerce: {
                    items: this.productListData(listID, listName, productArray, offset)
                }
            }
            if (!productArray || productArray.length === 0) {
                data.item_list_id = listID
                data.item_list_name = listName
            }
            this.push(data)
        },

        async sendBeginCheckout(basket) {
            let result = {
                event: 'begin_checkout',
                ecommerce: {
                    currency: basket.currency,
                    value: basket.productTotal, // sum of price * quantity, after applying product level and order level promos
                    items: this.productListData(null, null, basket.productItems, 0, {
                        applyOrderPromotions: true
                    })
                }
            }
            if (basket?.orderPriceAdjustments?.length > 0) {
                const appliedUniqueOrderCoupons = [
                    ...new Set(basket?.orderPriceAdjustments?.map((c) => c?.couponCode))
                ]
                result.ecommerce.coupon = appliedUniqueOrderCoupons.join(' ')
            }
            this.push(result)
        },

        async sendShipmentInfo(basket) {
            let result = {
                event: 'add_shipping_info',
                ecommerce: {
                    shipping_tier: basket?.shipments?.[0].shippingMethod?.id,
                    currency: basket.currency,
                    value: basket.productTotal, // sum of price * quantity, after applying product level and order level promos
                    items: this.productListData(null, null, basket.productItems, 0, {
                        applyOrderPromotions: true
                    })
                }
            }
            if (basket?.orderPriceAdjustments?.length > 0) {
                const appliedUniqueOrderCoupons = [
                    ...new Set(basket?.orderPriceAdjustments?.map((c) => c?.couponCode))
                ]
                result.ecommerce.coupon = appliedUniqueOrderCoupons.join(' ')
            }
            this.push(result)
        },

        async sendClickList(listID, listName, product, index, offset = 0) {
            this.push({
                event: 'select_item',
                ecommerce: {
                    items: [
                        {
                            ...this.productData(product),
                            item_list_id: listID,
                            item_list_name: listName,
                            index: index + 1 + offset
                        }
                    ]
                }
            })
        },

        async sendAddToWishlist(product) {
            let productData = this.productData(product)
            let result = {
                event: 'add_to_wishlist',
                ecommerce: {
                    value: productData.price * productData.quantity,
                    items: [productData]
                }
            }
            if (productData.currency) {
                result.ecommerce.currency = productData.currency
            }
            this.push(result)
        },

        async sendAddToCart(productItems, currency, isSubscribed = false) {
            const total =
                Math.round(productItems.reduce((acc, p) => acc + p.price * p.quantity, 0) * 100) /
                100
            if (productItems?.length) {
                productItems[0].is_subscribed = isSubscribed
            }
            this.push({
                event: 'add_to_cart',
                ecommerce: {
                    currency: currency,
                    value: total,
                    items: productItems
                }
            })
        },

        async sendRemoveFromCart(productItems, currency) {
            const total =
                Math.round(productItems.reduce((acc, p) => acc + p.price * p.quantity, 0) * 100) /
                100

            this.push({
                event: 'remove_from_cart',
                ecommerce: {
                    currency: currency,
                    value: total,
                    items: productItems
                }
            })
        },

        async sendSearch(searchTerm) {
            this.push({
                event: 'search',
                search_term: searchTerm
            })
        },

        async sendSelectPromotion(basket, couponCode) {
            let result = {
                event: 'select_promotion',
                ecommerce: {
                    items: []
                }
            }
            basket?.productItems?.forEach((item) => {
                item?.priceAdjustments?.forEach((adjustment) => {
                    if (adjustment.couponCode === couponCode) {
                        result.ecommerce.items.push({
                            item_id: adjustment.priceAdjustmentId,
                            promotion_id: adjustment.promotionId,
                            promotion_name: adjustment.itemText,
                            coupon: adjustment.couponCode,
                            discount: Math.abs(adjustment.price)
                        })
                    }
                })
            })

            basket?.orderPriceAdjustments?.forEach((adjustment) => {
                if (adjustment.couponCode === couponCode) {
                    result.ecommerce.promotion_id = adjustment.promotionId
                    result.ecommerce.promotion_name = adjustment.itemText
                }
            })

            if (result.ecommerce.items.length === 0) {
                delete result.ecommerce.items
            }

            this.push(result)
        },

        async sendPurchase(order) {
            if (localStorage && localStorage.getItem('sendPurchaseLastOrderNo') == order.orderNo) {
                return
            }
            let result = {
                event: 'purchase',
                ecommerce: {
                    currency: order.currency,
                    value: order.productTotal,
                    tax: order.taxTotal,
                    shipping: order.shippingTotal,
                    transaction_id: order.orderNo,
                    items: this.productListData(null, null, order.productItems, 0, {
                        applyOrderPromotions: true
                    })
                }
            }
            if (order?.orderPriceAdjustments?.length > 0) {
                const appliedUniqueOrderCoupons = [
                    ...new Set(order?.orderPriceAdjustments?.map((c) => c?.couponCode))
                ]
                result.ecommerce.coupon = appliedUniqueOrderCoupons.join(' ')
            }
            this.push(result)
            localStorage.setItem('sendPurchaseLastOrderNo', order.orderNo)
        },

        async sendPaymentInfo(order) {
            let result = {
                event: 'add_payment_info',
                ecommerce: {
                    currency: order.currency,
                    value: order.productTotal,
                    payment_type: order?.paymentInstruments?.[0]?.paymentMethodId,
                    items: this.productListData(null, null, order.productItems, 0, {
                        applyOrderPromotions: true
                    })
                }
            }
            if (order?.orderPriceAdjustments?.length > 0) {
                const appliedUniqueOrderCoupons = [
                    ...new Set(order?.orderPriceAdjustments?.map((c) => c?.couponCode))
                ]
                result.ecommerce.coupon = appliedUniqueOrderCoupons.join(' ')
            }
            this.push(result)
        },

        async changeQuantity(previous, current, product, currency) {
            const productData = this.productData(product)
            productData.quantity = current
            if (current > previous) {
                this.sendAddToCart([productData], currency)
                return
            }
            if (current < previous) {
                this.sendRemoveFromCart([productData], currency)
                return
            }
        }
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"acBlue": `#26becf`,
	"acGreen": `#8eb927`,
	"acWhite": `#fff`,
	"acNeutralsBlack": `#2a2a2a`,
	"acBlueText": `#0b393e`,
	"acBlueShade": `#1a818d`,
	"acBlueNight": `#156771`,
	"acBlueDark": `#1e98a6`,
	"acGreenText": `#2b380c`,
	"acGreenNight": `#556f17`,
	"acGreenShade": `#617e1a`,
	"acGreenDark": `#72941f`,
	"acBlue20": `#51cbd9`,
	"acBlueTint": `#93dfe7`,
	"acBlue70": `#beecf1`,
	"acBlue85": `#def5f8`,
	"acBlue90": `#e9f9fa`,
	"acBlue95": `#f4fcfd`,
	"acGreen20": `#a5c752`,
	"acGreenTint": `#c7dc93`,
	"acGreen70": `#ddeabe`,
	"acGreen85": `#eef5df`,
	"acGreen90": `#f4f8e9`,
	"acGreen95": `#f9fcf4`,
	"acGreen500": `#8eb927`,
	"acGrayNight": `#5c5c5c`,
	"acGrayShade": `#737373`,
	"acGrayDark": `#8f8f8f`,
	"acGray": `#a3a3a3`,
	"acGray20": `#b5b5b5`,
	"acGrayTint": `#d1d1d1`,
	"acGray70": `#e3e3e3`,
	"acGray85": `#f1f1f1`,
	"acGray90": `#f6f6f6`,
	"acGrayLight": `#fafafa`,
	"acGray200": `#c9c9c9`,
	"acGray300": `#aeaeae`,
	"acGray500": `#939393`,
	"acGray600": `#747474`,
	"acGray900": `#181818`,
	"acGray800": `#e6e9eb`,
	"acBlack": `#000`,
	"acBlack60": `#404040`,
	"formErrorColor": `#fe5c4c`,
	"formErrorMessageColor": `#ea001e`,
	"loqateSuggestionHoverColor": `#f0f0f0`,
	"loqateBoxColor": `#ccc`,
	"expandAddressColor": `#999`,
	"fieldBorder": `#ccc`,
	"loqateBoxShadow": `0 8px 17px 0 rgba(0,0,0,.2)`,
	"blue500": `#3182ce`,
	"rocheRegular": `"RocheSans-Regular"`,
	"rocheMedium": `"RocheSans-Medium"`,
	"acMinicartScrollBar": `#d9d9d9`
};
export default ___CSS_LOADER_EXPORT___;

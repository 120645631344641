export const getOutOfStockProductsList = (products, basket, idOnly = false) => {
    let outOfStockProductsList = []
    if (products && basket?.productItems?.length) {
        const bundledProductItems = basket?.productItems?.flatMap(item => item.bundledProductItems ?? []);
        basket.productItems.forEach((product) => {
            const selectedProd = products[product.productId]
            if (selectedProd?.inventory.stockLevel < product.quantity) {
                outOfStockProductsList.push(idOnly ? product.productId : {productName: product.productName, productId: product.productId})
            }
            if (bundledProductItems?.length) {
                bundledProductItems.forEach((bundleProduct) => {
                    if (bundleProduct?.productId === product?.productId) {
                        if (selectedProd?.inventory?.stockLevel < (product?.quantity + bundleProduct?.quantity)) {
                            outOfStockProductsList.push(idOnly ? product.productId : {productName: product.productName, productId: product.productId})
                        }            
                    }
                })
            }
        })
    }
    return outOfStockProductsList
}

export const isOutOfStock = (product, basket, products) => {
    let isOutOfStock = false
    if (product?.type?.master) {
        isOutOfStock = product?.variants?.some(variant => !variant.orderable)
    } else {
        isOutOfStock = !product?.inventory?.stockLevel
    }
    basket?.productItems?.forEach((product) => {
        const selectedProd = products[product.productId]
        if (selectedProd?.inventory?.stockLevel < product.quantity) {
            isOutOfStock = true
        }
    })
    return isOutOfStock
}
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import useAuthContext from '@salesforce/commerce-sdk-react/hooks/useAuthContext'
import useNavigation from '@salesforce/retail-react-app/app/hooks/use-navigation'
import {useAccessToken} from '@salesforce/commerce-sdk-react'
import {useShopperBasketsMutation} from '@salesforce/commerce-sdk-react'
import {useCommerceApi} from '@salesforce/commerce-sdk-react'
import {CookieStorage} from '@salesforce/commerce-sdk-react/auth/storage'
import {onClient} from '@salesforce/commerce-sdk-react/utils'
import {SLAS_SECRET_PLACEHOLDER} from '@salesforce/commerce-sdk-react/constant'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import {ApiClient} from '../services/api'

const usePasswordLessLogin = () => {
    const {getTokenWhenReady} = useAccessToken()
    const {site} = useMultiSite()
    const authContext = useAuthContext()
    const api = useCommerceApi()
    const appOrigin = getAppOrigin()
    const navigate = useNavigation()
    const mergeBasket = useShopperBasketsMutation('mergeBasket')
    const {
        app: {commerceAPI}
    } = getConfig()
    
    const sendVerificationCode = async (email) => {
        const slasClient = api.shopperLogin
        const options = {
            keySuffix: site?.id,
            sharedContext: !onClient()
        }
        const cookie = new CookieStorage(options)
        const {hostUrl} = commerceAPI.storefront
        try {
            await slasClient.authorizePasswordlessCustomer({
                body: {
                    userId: email,
                    channel_id: site?.id,
                    mode: 'callback',
                    callback_uri: `${hostUrl}/s/US/passwordlesslogin/callback?secret=${SLAS_SECRET_PLACEHOLDER}`,
                    user_id: email,
                    ...(cookie.get('usid') && {usid: cookie.get('usid')})
                }
            })
            return true
        } catch (e) {
            return false
        }
    }
   
    const verifyTokenCode = async (code, blockCheckout, newUser) => {
        const slasClient = api.shopperLogin
        const options = {
            keySuffix: site?.id,
            sharedContext: !onClient()
        }
        const cookie = new CookieStorage(options)
        try {
            const tokenPromise = slasClient.getPasswordLessAccessToken({
                body: {
                    pwdless_login_token: code,
                    grant_type: 'client_credentials',
                    hint: 'pwdless_login',
                    ...(cookie.get('usid') && {usid: cookie.get('usid')})             
                }
            })
            const loginResponse  = await authContext.queueRequest(() => tokenPromise, false);
            if (loginResponse?.access_token && !newUser) {
                /*
                    We only want to merge basket when the user is logged in as a recurring user. 
                    If firstName is not null, then user will not be a recurring one and we don't 
                    need to trigger basketMerge call in that case.
                */
                mergeBasket.mutate({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    parameters: {
                        createDestinationBasket: true
                    }
                })
                if (blockCheckout) {
                    navigate('/checkout')
                } else {
                    navigate('/account')
                }
                return true
            }
            return !!loginResponse?.access_token
        } catch (e) {
            return false
        }
    }

    const registerNewCustomer = async (email, firstName, lastName, isEmailSubscribed) => {
        const token = await getTokenWhenReady()
        const url = '/api/registerNewCustomer'
        const apiClient = new ApiClient(url, token, site)
        
        const res = await apiClient.post({
            body: JSON.stringify({
                userId: email,
                firstName: firstName,
                lastName: lastName,
                c_isEmailSubscribed: isEmailSubscribed
            })
        })
        return await res.json()
    }

    return {
        sendVerificationCode,
        verifyTokenCode,
        registerNewCustomer        
    }
}

export default usePasswordLessLogin
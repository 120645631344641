import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'

export * from '@salesforce/retail-react-app/app/utils/url'
export const productUrlBuilder = (product) => {
    return product?.c_productUrl
}

export const pdpCanonicalUrl = (product) => {
    const appOrigin = getAppOrigin()
    return appOrigin + productUrlBuilder(product)
}

import React, {useEffect} from 'react'
import useCustomApi from '../../hooks/use-custom-api'
import {Box} from '@salesforce/retail-react-app/app/components/shared/ui'

const ContentAsset = ({contentAssetId}) => {
    const {fetchContentAssetWithId, contentAssetBody} = useCustomApi()
    useEffect(() => {
        fetchContentAssetWithId(contentAssetId)
    }, [])
    return (<Box className='content-asset-body' dangerouslySetInnerHTML={{__html: contentAssetBody}} />)
}

export default ContentAsset

import React, {useEffect} from 'react'
import {
    List,
    ListItem
} from '@chakra-ui/react'
import useLoqate from '../../hooks/use-loqate'
function selectAddress(event, setSuggestions, setValue, getAddressSuggestion, clearErrors) {
    const addressObject = {
        addressType: event.target.dataset.type,
        addressId: event.target.dataset.id,
        text: event.target.dataset.text
    }
    getAddressSuggestion(addressObject, setSuggestions, setValue, clearErrors)
}

const AddressSuggestions = ({ suggestions, setSuggestions, setValue, clearErrors }) => {
    useEffect(() => {
        document.addEventListener('click', function (event) {
            if (!event.target.closest('.control-address1')) {
                setSuggestions({})
            }
        });
    }, [])
    const {
        getAddressSuggestion
    } = useLoqate()
    if (!suggestions.length) {
        return;
    }
    return (
        <List
            flexWrap={'wrap'}
            className="react-loqate-default-list"
        >
            {suggestions.length && suggestions.map(({ Description, Highlight, Id, Text, Type }, index) => {
                return (
                    <ListItem
                        key={index}
                        onClick={(event) => selectAddress(event, setSuggestions, setValue, getAddressSuggestion, clearErrors)}
                        className={Type === 'Street' || Type === 'BuildingNumber' || Type === 'Apartment' || Type === 'Container' ? 'react-loqate-list-item expand-street' : 'react-loqate-list-item street'}
                        data-description={Description}
                        data-highlight={Highlight}
                        data-id={Id}
                        data-text={Text}
                        data-type={Type}
                    >
                        {Text + ' ' + Description}
                    </ListItem>
                )
            })}
        </List>
    )
}

export default AddressSuggestions

import {extendTheme} from '@salesforce/retail-react-app/app/components/shared/ui'
import {overrides as base} from '@salesforce/retail-react-app/app/theme'
import fonts from './foundations/fonts'

export const overrides = {
    ...base,
    fonts
}

export default extendTheme(overrides)

import LogRocket from 'logrocket'
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

import setupLogRocketReact from 'logrocket-react'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import {useEffect} from 'react'
import {useUsid, useCustomerId, useCustomerType} from '@salesforce/commerce-sdk-react'


export default function useLogrocket() {
    const {app: appConfig} = getConfig()
    const {appId} = appConfig.logrocket
    const {usid} = useUsid()
    const customerId = useCustomerId()
    const {isRegistered} = useCustomerType()


    const privateFieldNames = [
        'ssn',
        'customerName',
        'firstName',
        'birthDate',
        'lastName',
        'fullName',
        'address1',
        'address2',
        'postalCode',
        'holderName',
        'email',
        'phone',
        'encryptedCardNumber',
        'encryptedSecurityCode',
        'encryptedExpiryMonth',
        'encryptedExpiryYear',
      ];

      const privateClassNameBlocklist =
      ['saved-address', 
      'entered-address', 
      'suggested-address',
      'order-body',
      'react-loqate-default-list']

    const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(privateFieldNames);

    useEffect(() => {
            if (appId) {
                LogRocket.init(appId, {
                    dom: {
                    inputSanitizer: true,
                    privateClassNameBlocklist: privateClassNameBlocklist,
                    },
                    network: {
                    requestSanitizer: requestSanitizer,
                    responseSanitizer: responseSanitizer
                    },
                    browser: {
                        urlSanitizer: url => {
                        let sanitizedUrl = url;
                        // redact the value of the Addressy query parameter
                        sanitizedUrl = sanitizedUrl.replace(/Text=([^&]*)/, 'Text=*')
                        // make sure you return the sanitized URL string
                        return sanitizedUrl;
                        },
                    },
                })
                LogRocket.getSessionURL(sessionURL => {
                    console.log(`with ${sessionURL}`);
                });
                setupLogRocketReact(LogRocket)
            }
        },
        [appId]
    )

    useEffect(() => {
        if (appId) {
            if (isRegistered && customerId) {
                LogRocket.identify(customerId, {
                    name: `Customer ${customerId}`,
                    type: 'registered'
                })
            }
            else if (usid) {
                LogRocket.identify(usid,  {
                    name: `User ${usid}`,
                    type: 'guest'
                })
            }
        }
    }, [appId, usid, customerId, isRegistered])
}

import {useCommerceApi} from '@salesforce/commerce-sdk-react'
import {helpers} from 'commerce-sdk-isomorphic'
import {useShopperBasketsMutation} from '@salesforce/commerce-sdk-react'
import {CookieStorage} from '@salesforce/commerce-sdk-react/auth/storage'
import {onClient} from '@salesforce/commerce-sdk-react/utils'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import useAuthContext from '@salesforce/commerce-sdk-react/hooks/useAuthContext'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'

const useIDPAuthHelper = () => {
    const api = useCommerceApi()
    const codeVerifier = helpers.createCodeVerifier()
    const mergeBasket = useShopperBasketsMutation('mergeBasket')
    const {site} = useMultiSite()
    const options = {
        keySuffix: site?.id,
        sharedContext: !onClient()
    }
    const authContext = useAuthContext()
    const {
        app: {commerceAPI}
    } = getConfig()
    return {
        async redirect({hint, redirectURI}) {
            // Defining variables
            const codeChallenge = await helpers.generateCodeChallenge(codeVerifier);
            const {clientConfig} = api.shopperExperience
            const {proxy} = clientConfig
            const {organizationId, siteId, clientId} = commerceAPI.parameters
            const cookie = new CookieStorage(options)
            const usid = cookie.get('usid')
            sessionStorage.setItem('idpCodeVerifier', codeVerifier)

            // Adding URL Parameters
            const urlParams = new URLSearchParams()
            urlParams.append('client_id', clientId)
            urlParams.append('channel_id', siteId)
            urlParams.append('code_challenge', codeChallenge)
            if (hint) {
                urlParams.append('hint', hint)
            }
            urlParams.append('redirect_uri', redirectURI)
            urlParams.append('response_type', 'code')
            if (usid) {
                urlParams.append('usid', usid) // mendatory field for basket merge
            }

            // Creating and returning URL redirect
            const redirectUrlString = `${proxy}/shopper/auth/v1/organizations/${organizationId}/oauth2/authorize/?${urlParams.toString()}`
            const redirectUrl = new URL(redirectUrlString)
            return {url: redirectUrl}
        },

        async login({code, redirectURI, usid, baskets}) {
            // Defining variables
            const {organizationId, siteId, clientId} = commerceAPI.parameters
            const codeVerifier = sessionStorage.getItem('idpCodeVerifier') || ''
            // Fetching token
            const slasClient = api.shopperLogin
 
            // Creating session and logging user in
            const tokenPromise = slasClient.getAccessToken({
                body: {
                    client_id: clientId,
                    channel_id: siteId,
                    code: code,
                    code_verifier: codeVerifier,
                    grant_type: 'authorization_code_pkce',
                    organizationId: organizationId,
                    redirect_uri: redirectURI,
                    usid: usid
                }
            });

            const loginResponse  = await authContext.queueRequest(() => tokenPromise, false);
            // Merge Baskets
            const hasBasketItem = baskets?.baskets?.[0]?.productItems?.length > 0
            if (hasBasketItem) {
                mergeBasket.mutate({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    parameters: {
                        createDestinationBasket: true
                    }
                })
            }
            return loginResponse
        }
    }
}

export default useIDPAuthHelper

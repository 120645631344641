// PiwikProScript.js
import React, { useEffect } from 'react';

const PiwikProScript = ({ piwikProTagContainerID, piwikProTagContainerURL, nonce }) => {
    useEffect(() => {
        if (piwikProTagContainerID && piwikProTagContainerURL) {
            
            const inlineScript = document.createElement('script');
            inlineScript.type = 'text/javascript';
            inlineScript.setAttribute('nonce', nonce);
            inlineScript.innerHTML = `
                (function(window, document, dataLayerName, id) {
                    window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});
                    var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
                    function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d=";expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
                    var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");
                    stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
                    var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),tags.nonce="${nonce}",isStgDebug&&qP.push("stg_debug");
                    var qPString=qP.length>0?("?"+qP.join("&")):"";
                    tags.async=!0,tags.src="${piwikProTagContainerURL}"+id+".js"+qPString,
                    scripts.parentNode.insertBefore(tags,scripts);
                    !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){
                    var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
                })(window, document, 'dataLayer', '${piwikProTagContainerID}');
            `;

            document.head.appendChild(inlineScript);

            return () => {
                document.head.removeChild(inlineScript);
            };
        }
    }, [piwikProTagContainerID, piwikProTagContainerURL, nonce]);

    return null;
};

export default PiwikProScript;

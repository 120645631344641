import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
export class LoqateAPI {

    async loqateFetch(apiUrl, method, body, removeLimit) {
        const headers = {
            'Content-Type': 'application/json'
        }

        if (body) {
            body = await this.buildBody(body)
            if (removeLimit) {
                delete body.addressLimit
            }
        }

        let response
        try {
            response = await fetch(apiUrl, {
                method: method,
                headers: headers,
                ...(body && {
                    body: JSON.stringify(body)
                })
            })
        } catch {
            console.warn('Loqate request failed')
        }

        if (!response?.ok) {
            return {}
        }

        const responseJson = await response.json()

        return responseJson
    }

    async apiDetails() {
        const {
            app: {commerceAPI}
        } = getConfig()
        const {locale, country, apiKey, addressCleansUrl, addressCapture, addressRetrieve, addressLimit} = commerceAPI.loqate
        return {
            locale: locale,
            country: country,
            apiKey: apiKey,
            addressCleansUrl: addressCleansUrl,
            addressCapture: addressCapture,
            addressRetrieve: addressRetrieve,
            addressLimit: addressLimit
        }
    }

    async buildBody(address) {
        const apiDetails = await this.apiDetails()
        const apiKey = apiDetails.apiKey;
        const requestBody = { Key: apiKey, Geocode: true, Addresses: [], Options: {Process: "Verify", Certify: true} }
        requestBody.Addresses.push(address)
        return requestBody
    }
}

function convertToLowercase(value) {
    return value ? value.toLowerCase().replace(/\b\w/g, char => char.toUpperCase()) : value
}

const useLoqate = () => {
    const loqate = new LoqateAPI()
    return {
        async getAddressValidation(address) {
            const apiDetails = await loqate.apiDetails()
            try {
                const method = 'POST'
                const addressObj = {
                    Address1: convertToLowercase(address.address1),
                    Address2: convertToLowercase(address.address2),
                    AdministrativeArea: address.stateCode,
                    Country: apiDetails.country,
                    Locality: convertToLowercase(address.city),
                    PostalCode: address.postalCode,
                }
                const endpoint = apiDetails.addressCleansUrl
                const response = await loqate.loqateFetch(endpoint, method, addressObj, true)
                const matchedAddress = response && response.length ? response[0].Matches : []
                const enteredAddress = response && response.length && response[0].Input
                return {
                    matches: matchedAddress.length ? [{
                        DeliveryAddress1: convertToLowercase(matchedAddress[0].DeliveryAddress1),
                        DeliveryAddress2: convertToLowercase(matchedAddress[0].DeliveryAddress2),
                        DeliveryAddress:  convertToLowercase(matchedAddress[0].DeliveryAddress),
                        Locality: convertToLowercase(matchedAddress[0].Locality),
                        AdministrativeArea: matchedAddress[0].AdministrativeArea,
                        PostalCode: matchedAddress[0].PostalCode?.split('-')[0]
                    }] : matchedAddress,
                    enteredAddress: enteredAddress ? {
                        Address1: convertToLowercase(enteredAddress.Address1),
                        Address2: convertToLowercase(enteredAddress.Address2),
                        Locality: convertToLowercase(enteredAddress.Locality),
                        AdministrativeArea: enteredAddress.AdministrativeArea,
                        PostalCode: enteredAddress.PostalCode?.split('-')[0]
                    } : enteredAddress,
                    isValid: response.Number === 2 || response && response.length && response[0].Matches && response[0].Matches.length && response[0].Matches[0].AQI === 'A'
                }
            } catch (err) {
                console.error(err)
            }
        },
        async getAddressSuggestion({ text, addressId, addressType }, setSuggestions, setValue, clearErrors) {
            const apiDetails = await loqate.apiDetails()
            try {
                const endpoint = new URL(addressType === 'Address' ? apiDetails.addressRetrieve : apiDetails.addressCapture)
                const method = 'GET'
                const body = null
                const params = {
                    Countries: apiDetails.country,
                    language: apiDetails.locale,
                    Key: apiDetails.apiKey,
                    limit: apiDetails.addressLimit,
                    Text: text,
                    Container: (addressType !== 'Address' ? addressId : '') || '',
                    Id: (addressType !== 'Address' ? '' : addressId) || '',
                }
                Object.keys(params).forEach(key => endpoint.searchParams.append(key, params[key]))
                const response = await loqate.loqateFetch(endpoint.href, method, body)
                if (addressType === 'Address') {
                    const selectedAddress = response.Items[0]
                    setValue('address1', selectedAddress.Line1 ? convertToLowercase(selectedAddress.Line1) : selectedAddress.Line1)
                    setValue('address2', selectedAddress.Line2 ? convertToLowercase(selectedAddress.Line2) : selectedAddress.Line2)
                    setValue('postalCode', selectedAddress.PostalCode ? selectedAddress.PostalCode.split('-')[0] : selectedAddress.PostalCode)
                    setValue('stateCode', selectedAddress.ProvinceCode)
                    setValue('city', selectedAddress.City ? convertToLowercase(selectedAddress.City) : selectedAddress.City)
                    clearErrors(['address2', 'postalCode', 'stateCode', 'city'])
                    setSuggestions({})
                } else {
                    setSuggestions(response.Items && response.Items.length && (response.Items[0].Error === '2' || response.Items[0].Error === '1002' || text.length < 2) ? {} :  response.Items)
                    if (document.getElementsByClassName('react-loqate-default-list').length) {
                        document.getElementsByClassName('react-loqate-default-list')[0].scrollTo({top: 0});
                    }
                }
            } catch (err) {
                console.error(err)
            }
        },
        async onAddressLookupType(text) {
            const apiDetails = await loqate.apiDetails()
            const endpoint = new URL(apiDetails.addressCapture)
            const method = 'GET'
            const params = {
                Countries: apiDetails.country,
                language: apiDetails.locale,
                Key: apiDetails.apiKey,
                limit: apiDetails.addressLimit,
                Text: text,
                Container: '',
                Id: '',
            }
            Object.keys(params).forEach(key => endpoint.searchParams.append(key, params[key]))
            const response = await loqate.loqateFetch(endpoint.href, method, null)
            return response.Items.filter(item => item.Type === 'Address').map(({Id: id, Text: name}) => ({id, name}))
        },
        async onAddressSelected(id) {
            const apiDetails = await loqate.apiDetails()
            const endpoint = new URL(apiDetails.addressRetrieve)
            const method = 'GET'
            const params = {
                Countries: apiDetails.country,
                language: apiDetails.locale,
                Key: apiDetails.apiKey,
                limit: apiDetails.addressLimit,
                Container: id,
                Id: id,
            }
            Object.keys(params).forEach(key => endpoint.searchParams.append(key, params[key]))
            const response = await loqate.loqateFetch(endpoint.href, method, null)
            return response?.Items?.length ? {
                id: id,
                name: response.Items[0].Label,
                street: response.Items[0].Street,
                houseNumberOrName: response.Items[0].BuildingNumber,
                postalCode: response.Items[0].PostalCode,
                city: response.Items[0].City,
                country: response.Items[0].CountryIso2,
                stateOrProvince: response.Items[0].ProvinceCode
            } : {}
        }
    }
}

export default useLoqate
